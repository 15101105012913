import React, {
  useState,
  useCallback,
  useContext,
  Fragment,
  useEffect,
} from "react"
import Layout from "components/member/signUp/layout.js"
import * as styles from "./group.module.css"
import { onQuestion } from "utils/memberUtils"
import Input from "components/tools/input.js"
import CheckBox from "components/tools/checkBox.js"
import json from "contents/term.json"
import modalContext from "context/modalContext"
import BottomBtnModal from "components/tools/modalComponents/bottomBtnModal.js"
import api from "apis"
import { getTermContents } from "utils"
import { term } from "utils/data"
import FileUploadBox from "components/tools/fileUploadBox.js"
import InputTitle from "components/tools/InputTitle.js"

const SignUpGroup = () => {
  const { setmodalOpen, setModalMain } = useContext(modalContext)
  const [termContents, setTermContents] = useState({})
  const termIdx = 7

  const [fileList, setFileList] = useState([])
  useEffect(() => {
    ;(async () => {
      const result = await api.getTermDetail(termIdx)

      if (result && result[0])
        setTermContents({
          key: result[0].kind,
          term_no: result[0].term_no,
          title: result[0].title,
          contents: getTermContents(result[0].TermContent),
        })
    })()
  }, [])

  const onTerm = () => {
    // 약관모달
    setmodalOpen(true)
    setModalMain(
      <BottomBtnModal
        close={() => {
          setmodalOpen(false)
        }}
        size={{
          w: 430,
          h: 590,
        }}
        confirm="확인"
      >
        <div className={styles.termBox}>
          <div>{termContents.title}</div>
          <div>{termContents.contents}</div>
        </div>
      </BottomBtnModal>
    )
  }

  return (
    <Layout title={"단체회원"}>
      <div className={`${styles.con} colContainer`}>
        <div className={`${styles.rowgap} rowContainer`}>
          <Input
            title={"담당자 이름"}
            titleSize={16}
            tagname={"manager"}
            style={{ fontFamily: "Noto Sans", lineHeight: 1.5, height: 30 }}
            inputStyle={{ fontSize: 14 }}
          />
          <Input
            title={"기관명"}
            titleSize={16}
            tagname={"company"}
            style={{ fontFamily: "Noto Sans", lineHeight: 1.5, height: 30 }}
            inputStyle={{ fontSize: 14 }}
          />
        </div>
        <div className={`${styles.rowgap} rowContainer`}>
          <Input
            title={"휴대전화"}
            titleSize={16}
            placeholder={"휴대폰 번호 입력"}
            tagname={"mobile"}
            style={{ fontFamily: "Noto Sans", lineHeight: 1.5, height: 30 }}
            inputStyle={{ fontSize: 14 }}
            left={
              <p
                style={{
                  marginTop: "10px",
                  fontSize: "11px",
                }}
              >
                피치서가 단체회원 가입 안내를 위해 정확한 번호를 입력해주세요.
              </p>
            }
          />
          <Input
            title={"이메일"}
            titleSize={16}
            placeholder={"이메일 주소 입력"}
            style={{ fontFamily: "Noto Sans", lineHeight: 1.5, height: 30 }}
            inputStyle={{ fontSize: 14 }}
            left={
              <p style={{ marginTop: "10px" }}>
                피치시가 단체회원 가입 안내가 이메일로 제공됩니다.
                <br /> 본인의 이메일을 정확하게 입력해주세요.
              </p>
            }
            tagname={"email"}
          />
        </div>
        <div className="colContainer">
          <div style={{ fontWeight: "700", marginRight: "680px" }}>
            <InputTitle
              name={"단체 소개"}
              titleSize={16}
              style={{ fontFamily: "Noto Sans", lineHeight: 1.5, height: 30 }}
              inputStyle={{ fontSize: 14 }}
            />
          </div>
          <textarea
            className={styles.textBox}
            name="introduce"
            placeholder="  기관 소개 및 활동사항에 대한 내용을 작성해주세요."
          />
          <div className={styles.filesList}>
            <FileUploadBox
              fileList={fileList}
              setFileList={setFileList}
              custom
              btnstyle={{ left: "155px" }}
            />
          </div>
        </div>
        {/*  */}
        <div style={{ marginTop: "70px" }}>
          <CheckBox
            id={"termCheck"}
            name={"termcheck"}
            className={"circleCheckBox"}
          />
          <div className={styles.term}>
            개인정보 수집 이용 동의 <p>(필수)</p> {/* 여기 */}
            <div onClick={onTerm}>자세히 보기</div>
          </div>
          <div className="errMsg" name="termcheck"></div>
        </div>
        <div
          className={"longBtn"}
          style={{ marginTop: 40, marginBottom: 85, fontWeight: 500 }}
          onClick={() => {
            onQuestion(setmodalOpen, setModalMain, fileList)
          }}
        >
          문의하기
        </div>
      </div>
    </Layout>
  )
}

export default SignUpGroup
